import { memo, useEffect, useRef, useState } from "react";
import { useGetAgentsJSONMutation, useGetPDFUploadPresignedUrlMutation, useUploadFileToS3Mutation } from '@circuitry-ai/doc-data';

import { CtyButton, CtyShowToast, CtySpinner } from "@circuitry-ai/doc-ui";
import { Icon } from "@iconify/react";
import { Input } from "@nextui-org/react";
import Viewer from 'viewerjs';


export interface OcrscannerProps { }
function extractHighlights(data) {
  return data.map(item => ({
    text: item.text,
    polygon: item.polygon,
  }));
}
export function Ocrscanner(props: OcrscannerProps) {
  const [ocrScannerInfo, setOcrScannerInfo] = useState({
    documentType: 'ocr',
    imageFile: null as File | null,
    imageURL: "",
    extractedText: null as object | null,
    loading: false
  });

  const [getPresignedUrl] = useGetPDFUploadPresignedUrlMutation();
  const [uploadtos3] = useUploadFileToS3Mutation();
  const [getOcrText] = useGetAgentsJSONMutation();
  const [highlights, setHighlights] = useState<any[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState<null | number>(null);
  const viewerRef = useRef<any>(null);
  const imgRef = useRef<any>(null);
  const createImageWithHighlights = async (
    imageUrl: string,
    highlights: Highlight[]
  ): Promise<string> => {
    const img = new Image();
    img.src = imageUrl;

    return new Promise((resolve) => {
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d")!;

        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;

        // Draw the image
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Draw the highlights
        highlights.forEach((highlight) => {
          ctx.beginPath();
          highlight.polygon.forEach(([x, y], index) => {
            const scaledX = x * (canvas.width / img.naturalWidth);
            const scaledY = y * (canvas.height / img.naturalHeight);
            if (index === 0) {
              ctx.moveTo(scaledX, scaledY);
            } else {
              ctx.lineTo(scaledX, scaledY);
            }
          });
          ctx.closePath();
          ctx.strokeStyle = "red";
          ctx.lineWidth = 2;
          ctx.stroke();
          ctx.globalAlpha = 0.3; // Add transparency if needed
          ctx.fillStyle = "rgba(255, 0, 0, 0.3)";
          ctx.fill();
        });

        // Export the canvas as a data URL
        resolve(canvas.toDataURL("image/png"));
      };
    });
  };

  useEffect(() => {
    if (imgRef.current && ocrScannerInfo.imageFile) {
      const loadWithHighlights = async () => {
        const highlightedImageURL = await createImageWithHighlights(
          ocrScannerInfo.imageURL,
          highlights
        );

        if (viewerRef.current) {
          viewerRef.current.destroy();
        }
        viewerRef.current = new Viewer(imgRef.current, {
          url: () => highlightedImageURL, // Use the new image with highlights
          title: false,
          toolbar: true,
        });
      };

      loadWithHighlights();
    }

    return () => {
      if (viewerRef.current) {
        viewerRef.current.destroy();
        viewerRef.current = null;
      }
    };
  }, [ocrScannerInfo.imageURL, highlights]);
  const [ocrScannerError, setOcrScannerError] = useState<any>(null);
  const handleImageClick = () => {
    if (viewerRef.current) {
      viewerRef.current.show();
    }
  };
  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setOcrScannerInfo(prev => ({ ...prev, loading: true, extractedText: null, imageFile: file }));
    setOcrScannerError(null);
    try {
      const payLoad = {
        agent_id: '31457ce4-b51d-471e-8858-04652a3ae35a',
        data: [{
          document_type: ocrScannerInfo.documentType,
          filename: file.name,
          parts: 0,
          file_type: file.type.split('/')[1]
        }]
      };

      const uploadURL: any = await getPresignedUrl(payLoad).unwrap();
      await uploadtos3({
        file,
        presignedUrl: uploadURL.presigned_urls.part_1,
        CtyShowToast
      }).unwrap();

      // Allow time for processing
      await new Promise((resolve) => setTimeout(resolve, 2000));

      const ocrData = await getOcrText({ source_id: uploadURL.source_id }).unwrap();
      const highlights = extractHighlights(ocrData);
      setHighlights(highlights);
      setOcrScannerInfo(prev => ({ ...prev, extractedText: ocrData }));
    } catch (error) {
      CtyShowToast('error', error?.message || 'Failed to process image');
      setOcrScannerError(error);
    } finally {
      setOcrScannerInfo(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (ocrScannerInfo?.imageFile) {
      const url = URL.createObjectURL(ocrScannerInfo?.imageFile);
      setOcrScannerInfo(prev => ({ ...prev, imageURL: url }));

      return () => URL.revokeObjectURL(url);
    }
  }, [ocrScannerInfo?.imageFile]);
  function renderHighlightsContent(highlights: any[], ocrScannerInfo: any) {
    if (highlights?.length > 0) {
      return highlights.map((highlight: any, index) => (
        <div
          key={Math.random() + highlight.text}
          className="p-4 border-b border-gray-200"
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
          onFocus={() => setHoveredIndex(index)} // For keyboard users
          onBlur={() => setHoveredIndex(null)}  // For keyboard users
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              setHoveredIndex(index);
            }
          }}
          role="button"
          tabIndex={0}
          aria-label={`Highlight text: ${highlight.text}`}
        >
          <p className="text-sm text-gray-600">{highlight.text}</p>
        </div>

      ));
    }

    if (!ocrScannerInfo?.loading && ocrScannerInfo?.imageFile) {
      return (
        <div className="flex flex-col items-center justify-center py-8 text-gray-500">
          <p className="text-sm lg:text-base">Processing Image data...</p>
        </div>
      );
    }

    return (
      <div className="flex flex-col items-center justify-center py-8 text-gray-500">
        <Icon icon="material-symbols:upload" />
        <p className="text-sm lg:text-base">Upload an Image to extract text</p>
      </div>
    );
  }
  const resetOcrScannerInfo = () => {
    setOcrScannerInfo({
      documentType: 'ocr',
      imageFile: null,
      imageURL: "",
      extractedText: null,
      loading: false
    });
    setHighlights([]);
    setHoveredIndex(null);
  };
  return (
    <div className="flex flex-col sm:w-[100%] md:w-auto lg:w-auto xl:w-auto bg-gray-50 h-full overflow-y-auto p-3 gap-3 lg:flex-row min-h-screen mb-4">
      {/* Image Viewer Section */}
      <div className="flex-1 lg:w-1/2 overflow-y-auto">
        <div className="bg-white rounded-lg shadow-sm p-4 lg:p-6 mb-4">
          <h1 className="text-xl lg:text-2xl font-bold text-gray-800 mb-4 lg:mb-6">OCR Scanner</h1>

          <div className="flex flex-col lg:flex-row gap-4 mb-4 lg:mb-6">

            <div className="w-full lg:flex-1 lg:self-end lg:w-[50%]">
              {!ocrScannerInfo?.imageFile ? (
                <label className="flex flex-col items-center justify-center w-full p-6 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-blue-500 hover:bg-blue-50 transition-colors">
                  <div className="flex flex-col items-center">
                    <Icon icon="material-symbols:upload" />
                    <p className="text-sm text-gray-600">
                      <span className="font-semibold">Upload Image</span>
                    </p>
                    <p className="text-xs text-gray-500">Image files only</p>
                  </div>
                  <Input
                    type="file"
                    accept="image/*"
                    onChange={handleFileUpload}
                    className="hidden"
                  />
                </label>
              ) : (
                <div className="flex items-center gap-3 p-3 bg-blue-50 rounded-lg">
                  <span
                    className="text-sm text-blue-700 truncate w-full break-words"
                    title={ocrScannerInfo?.imageFile.name}
                  >
                    {ocrScannerInfo?.imageFile.name?.length > 50
                      ? `${ocrScannerInfo.imageFile.name.slice(0, 50)}...`
                      : ocrScannerInfo?.imageFile.name}
                  </span>

                  <CtyButton
                    onPress={resetOcrScannerInfo}
                    className="p-1 hover:bg-blue-100 rounded-full transition-colors min-w-0"
                    variant="flat"
                    color="white"
                  >
                    <Icon icon="radix-icons:cross-2" />
                  </CtyButton>
                </div>
              )}
            </div>
          </div>

          {ocrScannerInfo?.imageFile && (
            <div className="sm:min-h-[400px] bg-gray-50 rounded-lg lg:h-[70vh] flex items-center justify-center overflow-auto">
              <ImageHighlighterMemoized
                imageUrl={ocrScannerInfo?.imageURL}
                highlights={highlights}
                hoveredIndex={hoveredIndex}
                handleImageClick={handleImageClick}
                imageRef={imgRef}
              />
            </div>
          )}
        </div>
      </div>

      {/* Data Display Section */}
      <div className="flex-1 p-4 mb-16 lg:w-1/2 overflow-y-auto border-t lg:border-l lg:border-t-0 border-gray-200">
        {ocrScannerError && ocrScannerError.message ? <div>
          <h2 className="text-lg p-2 lg:text-xl font-semibold text-gray-800 lg:mb-6">Error</h2>
          <div className="rounded-lg shadow-sm bg-red-50 p-4 text-red-800">
            <p className="text-sm lg:text-base">{ocrScannerError.message}</p>
          </div>
        </div> : <div className="rounded-lg shadow-sm relative bg-white">
          <h2 className="text-lg p-2 lg:text-xl font-semibold text-gray-800 lg:mb-6">Extracted Text</h2>

          {ocrScannerInfo?.loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 z-50">
              <CtySpinner size="lg" />
            </div>
          )}

          <div className="overflow-y-auto">
            {renderHighlightsContent(highlights, ocrScannerInfo)}
          </div>
        </div>}
      </div>
    </div>
  );
}

export default Ocrscanner;



interface Highlight {
  polygon: [number, number][];
  text?: string;
}

interface Dimensions {
  width: number;
  height: number;
  scale: number;
}

interface ImageHighlighterProps {
  imageUrl: string;
  highlights: Highlight[];
  hoveredIndex: number | null;
  handleImageClick: () => void;
  imageRef: React.RefObject<HTMLImageElement>;
}

const ImageHighlighter: React.FC<ImageHighlighterProps> = memo(
  ({ imageUrl, highlights, hoveredIndex, handleImageClick, imageRef }) => {
    const [dimensions, setDimensions] = useState<Dimensions>({
      width: 0,
      height: 0,
      scale: 1,
    });

    const containerRef = useRef<HTMLDivElement>(null);

    // Calculate dimensions of the image
    useEffect(() => {
      const updateDimensions = () => {
        if (imageRef.current && containerRef.current) {
          const { naturalWidth, naturalHeight } = imageRef.current;
          const containerWidth = containerRef.current.clientWidth;
          const scale = containerWidth / naturalWidth;
          const height = naturalHeight * scale;

          setDimensions({
            width: containerWidth,
            height,
            scale,
          });
        }
      };

      const img = imageRef.current;
      if (img?.complete) {
        updateDimensions();
      } else if (img) {
        img.onload = updateDimensions;
      }

      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
    }, [imageUrl]);

    // Helper to create polygon points
    const createPolygonPoints = (polygon: [number, number][], scale: number): string =>
      polygon.map(([x, y]) => `${x * scale},${y * scale}`).join(" ");

    return (
      <div
        ref={containerRef}
        className="relative w-full h-full overflow-auto"
      >

        <img
          ref={imageRef}
          src={imageUrl}
          alt="Document with highlights"
          className="absolute top-0 left-0 w-full h-full object-contain"
          onClick={handleImageClick}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleImageClick();
            }
          }}
          tabIndex={0}
          aria-label="Open document viewer"
        />


        <svg
          className="absolute top-0 left-0 w-full h-full"
          viewBox={`0 0 ${dimensions.width} ${dimensions.height ?? 0}`}
          style={{ pointerEvents: "none" }}
        >
          {highlights.map((highlight, index) => (
            <polygon
              key={Math.random() + (highlight.text ?? "")}
              points={createPolygonPoints(highlight.polygon, dimensions.scale)}
              fill="none"
              stroke="#ff0000"
              strokeWidth={hoveredIndex === index ? "4" : "2"}
              className="opacity-70 hover:opacity-100 transition-opacity duration-200"
            />
          ))}
        </svg>
      </div>
    );
  }
);

// Memoize to prevent unnecessary re-renders
const ImageHighlighterMemoized = memo(ImageHighlighter);





