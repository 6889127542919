import { useAuth0 } from '@auth0/auth0-react';
import { Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { RootState, setToken, setUserDetails, useAppDispatch } from '@circuitry-ai/doc-data';
import { useSelector } from 'react-redux';
import Layout from './layout/Layout';
import Login from './Login';
import { CircularProgress, NextUIProvider } from '@nextui-org/react';
import {
  AdvisorList,
  AdvisorView,
  Advisorknowledge,
  ConversationHistory,
  ConversationMessages,
  Dashboard,
  Knowledge,
  Settings,
  AnalystBoard,
  AnalyticsList,
  Agents
} from './lazyload/lazyload';
import { AbilityContext, buildAbility, CtyToast, FileViewer, FlowEditorWrapper } from '@circuitry-ai/doc-ui';
import { Userpilot } from 'userpilot';
import { EmbedZapier, Ocrscanner, PdfScanner, Transcriber } from '@circuitry-ai/doc-agent';
import 'viewerjs/dist/viewer.css';

export function App() {
  const { isLoading, isAuthenticated, getIdTokenClaims, user } = useAuth0();
  const dispatch = useAppDispatch();
  const token = useSelector((state: RootState) => state.auth.token);
  const navigate = useNavigate();
  const [value, setValue] = useState<number>(0);
  const location = useLocation();
  useEffect(() => {
    Userpilot.reload();
  }, [location]);
  useEffect(() => {
    if (isAuthenticated) {
      Userpilot.identify(user?.name ?? 'guest', {
        name: user?.nickname,
        email: user?.email,
        created_at: user?.updated_at,
        organization: user?.org_name,
        company: {
          name: user?.org_name,
        },
      });
      if (token === null) {
        const fetchToken = async () => {
          const token = await getIdTokenClaims();
          dispatch(setToken(token?.__raw));
          dispatch(setUserDetails({ details: user, isAuthenticated: isAuthenticated, isSSOUser: user?.sub?.includes('samlp|') ? true : false }));
        };
        fetchToken();
      }
    }
  }, [isAuthenticated, dispatch, getIdTokenClaims, token, user]);
  useEffect(() => {
    const interval = setInterval(() => {
      setValue((v) => (v >= 100 ? 0 : v + 10));
    }, 500);
    if (!isLoading) clearInterval(interval);
    return () => clearInterval(interval);
  }, [isLoading]);
  const ability = buildAbility(user || { role: 'guest', permissions__C: [] });
  let content;
  if (isLoading) {
    content = (
      <div className="flex h-screen items-center justify-center">
        <CircularProgress
          aria-label="Loading..."
          size="lg"
          value={value}
          color="warning"
          showValueLabel={true}
        />
      </div>
    );
  } else if (isAuthenticated) {
    content = (
      <Layout>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/advisors" element={<AdvisorList />} />
          <Route path="/advisors/:advisor_id" element={<AdvisorView />} />
          <Route path="/advisors/createadvisor" element={<AdvisorView />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/conversations" element={<ConversationHistory />} />
          {/* <Route path="/search-with-milvus" element={<ConversationHistoryMilves />} /> */}
          <Route path={`/conversations/:sender_id/:advisor_id`} element={<ConversationMessages />} />
          <Route path="/knowledge" element={<Knowledge />} />
          <Route path="/knowledge/:advisor_id" element={<Advisorknowledge />} />
          <Route path="/file/:advisorId/:fileName" element={<FileViewer />} />
          <Route path="/analytics" element={<AnalyticsList />} />
          <Route path="/analytics/:reportId" element={<AnalystBoard />} />
          <Route path="/settings/zapier" element={<EmbedZapier />} />
          <Route path="/agents" element={<Agents />} />
          <Route path="/agents/pdfscanner" element={<PdfScanner />} />
          <Route path="/agents/ocrscanner" element={<Ocrscanner />} />
          <Route path="/agents/automation" element={<EmbedZapier />} />
          <Route path="/agents/transcriber" element={<Transcriber />} />
          <Route path="/flow" element={<FlowEditorWrapper />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <CtyToast />
      </Layout>
    );
  } else {
    content = <Login />;
  }

  return (
    <AbilityContext.Provider value={ability}>
      <NextUIProvider navigate={navigate}>{content}</NextUIProvider>
    </AbilityContext.Provider>
  );
}

export default App;
