import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import * as d3 from 'd3';
import { useGetPdfLinkForConversationsMutation } from '@circuitry-ai/doc-data';
import { useParams } from 'react-router-dom';
import CtySpinner from '../spinner/CtySpinner';
import { CtyButton } from '../button/CtyButton';
import { Image, Tooltip } from '@nextui-org/react';
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';
import { Icon } from '@iconify/react';
interface PartsSvgViewerProps {
  svgUrl: string;
  sourceIdData: any;
  currentActivePartsRowData?: any;
  setSelectedRowId: any;
}


// Loading spinner component
const LoadingSpinner = ({ loadingText }: { loadingText: string; }) => (
  <div className="flex justify-center items-center min-h-[400px]">
    <CtySpinner size="md" color="primary" />
  </div>
);
function ensureSvgOverflowVisible(svgText) {
  // Use a regex to find the <svg> tag and check for the overflow attribute
  const svgTagRegex = /<svg[^>]*>/;
  const match = svgText.match(svgTagRegex);

  if (match) {
    const svgTag = match[0];
    if (!/overflow=["']visible["']/.test(svgTag)) {
      // Add overflow="visible" to the <svg> tag
      const updatedSvgTag = svgTag.replace(
        /<svg/,
        '<svg overflow="visible"'
      );
      return svgText.replace(svgTag, updatedSvgTag);
    }
  }
  return svgText; // Return unchanged if already has overflow="visible"

}



const PartsSvgViewer: React.FC<PartsSvgViewerProps> = ({ svgUrl, sourceIdData, currentActivePartsRowData, setSelectedRowId }) => {
  const partsList = useMemo(() => {
    return sourceIdData[0]?.element_refs[0]?.text || [];
  }, [sourceIdData]);

  const svgContainerRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const zoomRef = useRef<d3.ZoomBehavior<Element, unknown> | null>(null);
  const [tooltipData, setTooltipData] = useState<PartInfo | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number; } | null>(null);
  const [svgLoadError, setSvgLoadError] = useState<string | null>(null);
  const fileName = useParams<{ fileName: string; }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const activeReferenceNumber =
    currentActivePartsRowData?.[1]?.props?.children || null;
  const getSvgScreenCoordinates = (element: SVGElement): { x: number; y: number } => {
    const svgElement = svgRef.current;
    if (!svgElement || !svgContainerRef.current) return { x: 0, y: 0 };

    // Get the SVG's CTM (Current Transform Matrix)
    const ctm = element.getScreenCTM();
    if (!ctm) return { x: 0, y: 0 };

    // Get the bounding box of the element
    const bbox = element.getBBox();

    // Calculate center point of the text element in SVG coordinates
    const centerX = bbox.x + bbox.width / 2;
    const centerY = bbox.y + bbox.height / 2;

    // Transform the point to screen coordinates
    const point = svgElement.createSVGPoint();
    point.x = centerX;
    point.y = centerY;
    const screenPoint = point.matrixTransform(ctm);

    // Get the container's position
    const containerRect = svgContainerRef.current.getBoundingClientRect();

    // Calculate final coordinates relative to the container
    return {
      x: screenPoint.x - containerRect.left,
      y: screenPoint.y - containerRect.top
    };
  };
  // Function to initialize or update parts highlighting
  const updatePartsHighlighting = useCallback(() => {
    if (!svgRef.current) return;

    const svgElement = d3.select(svgRef.current);
    const textElements = svgElement.selectAll("g text"); // Select text elements from all <g> tags

    const handleTextClick = (
      textElement: d3.Selection<Element, unknown, null, undefined>,
      part: any,
      isTableRowClicked: boolean
    ) => {
      if (!textElement.node() || !svgRef.current || !svgContainerRef.current) return;

      // Get accurate screen coordinates
      const coords = getSvgScreenCoordinates(textElement.node() as SVGElement);

      // Set tooltip data
      const toolTipData = {
        parts_number: isTableRowClicked
          ? currentActivePartsRowData?.[2]?.props?.children
          : part?.part_info?.parts_number ?? "",
        description: isTableRowClicked
          ? currentActivePartsRowData?.[3]?.props?.children
          : part?.part_info?.description ?? "",
      };

      if (!isTableRowClicked) {
        const tableRowId =
          part.reference_number +
          "-" +
          part.part_info.parts_number +
          "-" +
          part.part_info.description;
        setSelectedRowId && setSelectedRowId(tableRowId);
      }

      setTooltipData(toolTipData);
      setTooltipPosition(coords);
    };

    textElements.each(function () {
      const textElement = d3.select(this);
      const textContent = textElement.text().trim();
      const part = partsList.find((p) => p.reference_number === textContent);

      if (part) {
        textElement.classed("highlighted", true).on("click", () =>
          handleTextClick(textElement, part, false)
        );
      }
    });

    if (activeReferenceNumber) {
      const targetTextElement = textElements.filter((_, i, nodes) =>
        d3.select(nodes[i]).text().trim() === activeReferenceNumber
      );
      const part = partsList.find((p) => p.reference_number === activeReferenceNumber);
      handleTextClick(d3.select(targetTextElement.node()), part, true);
    }
  }, [partsList, activeReferenceNumber, currentActivePartsRowData]);

  const renderSvgAndTooltip = () => {
    return (
      <div className='relative'>
        <div ref={svgContainerRef} style={{ minWidth: '1000px', position: "relative", overflow: "hidden" }}></div>

        <TooltipComponent
          tooltipData={tooltipData}
          tooltipPosition={tooltipPosition}
          setTooltipData={setTooltipData}
        />
      </div>
    );
  };


  useEffect(() => {
    const loadSvg = async () => {
      setIsLoading(true);
      setSvgLoadError(null);
      if (!svgUrl) {
        setSvgLoadError("No SVG URL provided");
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(svgUrl);
        if (!response.ok) {
          throw new Error(`Failed to fetch SVG: ${response.statusText}`);
        }
        const svgText = await response.text();
        const updatedSvgText = ensureSvgOverflowVisible(svgText);

        const parser = new DOMParser();
        const svgDocument = parser.parseFromString(updatedSvgText, "image/svg+xml");
        const svgElement = svgDocument.documentElement;


        if (svgContainerRef.current) {
          svgContainerRef.current.innerHTML = "";
          svgContainerRef.current.appendChild(svgElement);

          // Disable native scroll when mouse wheel is used inside the container.
          svgContainerRef.current.addEventListener(
            "wheel",
            (e) => {
              e.preventDefault();
            },
            { passive: false }
          );
        }
        svgRef.current = svgElement;
        svgElement.setAttribute("viewBox", "-130 -20 600 594");


        const svgSelection = d3.select(svgElement);
        const zoomGroup = svgSelection.append("g");
        svgSelection.selectAll(":scope > *:not(g)")
          .each(function () {
            zoomGroup.node()?.appendChild(this);
          });
        // Also, if there are already one or more <g> elements as direct children,
        // move them into the new wrapper (this avoids double-wrapping if everything’s already in one <g>)
        svgSelection.selectAll(":scope > g")
          .filter(function () {
            // Filter out the newly created wrapper so you don’t move it again
            return this !== zoomGroup.node();
          })
          .each(function () {
            zoomGroup.node()?.appendChild(this);
          });


        // Apply Zoom functionality
        const zoom = d3.zoom()
          .scaleExtent([0.1, 200])
          .on("zoom", (event) => {
            zoomGroup.attr("transform", event.transform);
          });

        d3.select(svgElement).call(zoom);
        zoomRef.current = zoom;

        updatePartsHighlighting();
      } catch (error) {
        console.error("Error loading SVG:", error);
        setSvgLoadError(error instanceof Error ? error.message : "Unknown error loading SVG");
      } finally {
        setIsLoading(false);
      }
    };

    loadSvg();
  }, [svgUrl]);


  // Update highlighting whenever `currentActivePartsRowData` changes
  useEffect(() => {
    updatePartsHighlighting();
  }, [currentActivePartsRowData, updatePartsHighlighting]);

  // Zoom Controls
  const handleZoomIn = () => {
    if (svgRef.current && zoomRef.current) {
      d3.select(svgRef.current).transition().duration(200).call(zoomRef.current.scaleBy, 1.2);
    }
  };

  const handleZoomOut = () => {
    if (svgRef.current && zoomRef.current) {
      d3.select(svgRef.current).transition().duration(200).call(zoomRef.current.scaleBy, 0.8);
    }
  };
  const handleReset = () => {
    if (svgRef.current && zoomRef.current) {
      d3.select(svgRef.current)
        .transition()
        .duration(200)
        .call(zoomRef.current.transform, d3.zoomIdentity);
    }
  };
  return (
    <div className="flex flex-col h-full min-h-[400px] w-full relative">
      {fileName.fileName && (
        <div className="flex flex-wrap w-full gap-10 items-center justify-center p-4 border-b border-grey">
          <h3 className="font-bold text-center">{fileName?.fileName}</h3>
        </div>
      )}
      {isLoading && <LoadingSpinner text="Preparing Svg...." />}

      <div className="relative">
        {/* Zoom Controls */}
        <div className="absolute top-4 left-4 flex gap-2 z-10">
          <div className="flex flex-col gap-2">
            <button className="p-2 bg-gray-800 text-white rounded cursor-pointer" onClick={handleZoomIn} title='Zoom In'>
              +
            </button>
            <button className="p-2 bg-gray-800 text-white rounded cursor-pointer" onClick={handleZoomOut} title='Zoom Out'>
              −
            </button>
            <button className="p-2 bg-gray-800 text-white rounded cursor-pointer" onClick={handleReset} title='Reset Zoom'>
              <Icon icon="system-uicons:reset" width="21" height="21" />
            </button>
          </div>
        </div>

        {/* SVG Container */}
        {
          svgLoadError ? (
            <div className="text-red-500 flex justify-center items-center h-[400px]">
              {svgLoadError}
            </div>
          ) : (
            renderSvgAndTooltip()
          )
        }

      </div>
    </div>
  );
};


interface PartInfo {
  parts_number: string;
  description: string;
}

interface PartSvgViewerInlineProps {
  message: any;
  advisorId: string;
  currentActivePartsRowData: any;
  setSelectedRowId: any;
}
const PartSvgViewerInline: React.FC<PartSvgViewerInlineProps> = ({ message, advisorId, currentActivePartsRowData, setSelectedRowId }) => {
  const [elementRefs, setElementRefs] = useState<any[]>(() => ([{ source_id: message?.metaData?.[1]?.source_ids[0].source_id, page_labels: [] }])); // Initialize with empty array
  const [getPdfLink, { error, isError }] = useGetPdfLinkForConversationsMutation();
  const [svgUrl, setSvgUrl] = useState<string>('');
  const [isPartsDataLoading, setIsPartsDataLoading] = useState(false);
  const sourceIdData = message?.metaData?.[1]?.source_ids;
  const imgRef = useRef<any>(null);
  const viewerRef = useRef<any>(null);
  console.log('sourceIdData', sourceIdData);
  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;
    if (imgRef.current && svgUrl) {
      if (viewerRef.current) {
        viewerRef.current.destroy();
      }
      timerId = setTimeout(() => {
        viewerRef.current = new Viewer(imgRef.current, {
          title: false,
          focus: false,
          toolbar: {
            zoomIn: {
              show: 1,
              size: 'large',
            },
            zoomOut: {
              show: 1,
              size: 'large',
            },
            oneToOne: {
              show: 1,
              size: 'large',
            },
            reset: {
              show: 1,
              size: 'large',
            },
            prev: {
              show: 1,
              size: 'large',
            },
            play: {
              show: 4,
              size: 'large',
            },
            next: {
              show: 1,
              size: 'large',
            },
            rotateLeft: {
              show: 1,
              size: 'large',
            },
            rotateRight: {
              show: 1,
              size: 'large',
            },
            flipHorizontal: {
              show: 1,
              size: 'large',
            },
            flipVertical: {
              show: 1,
              size: 'large',
            },
          },
          navbar: false,
          slideOnTouch: false,
          fullscreen: false,
        });
      }, 100);
    }
    return () => {
      if (viewerRef.current) {
        viewerRef.current.destroy();
      }
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [svgUrl]);
  // Fetch SVG URL
  useEffect(() => {
    if (elementRefs.length > 0) {
      (async () => {
        try {
          setIsPartsDataLoading(true);
          const getPdfLinkRequestPayload = {
            advisor_id: advisorId,
            source_ids: elementRefs,
          };

          const urlResponse = await getPdfLink(getPdfLinkRequestPayload).unwrap();
          setSvgUrl(urlResponse?.data?.[0]?.full_source_presigned_url || '');
        } catch (e) {
          console.error((e as Error)?.message || 'Error Fetching Image');
        } finally {
          setIsPartsDataLoading(false);
        }
      })();
    }
  }, [elementRefs, advisorId, getPdfLink]);
  return (
    <div>
      {
        sourceIdData[0]?.file_name?.trim()?.endsWith('svg') ? <div className="w-full relative min-w-[800px] ">
          {isError ? (
            <div className="text-red-500 flex justify-center items-center h-[600px]">
              {/* Display error message if available, or a default fallback */}
              {error?.message || "Failed to load SVG, please try again later."}
            </div>
          ) : isPartsDataLoading ? (
            <LoadingSpinner text={"Fetching Svg ...."} />
          ) : (
            <PartsSvgViewer svgUrl={svgUrl} sourceIdData={sourceIdData} currentActivePartsRowData={currentActivePartsRowData} setSelectedRowId={setSelectedRowId} />
          )}
        </div> : <div ref={imgRef}>
          <Image src={svgUrl} alt={sourceIdData[0].file_name} />
        </div>
      }
    </div>


  );
};

const TooltipComponent = ({ tooltipData, tooltipPosition, setTooltipData }) => {
  return (
    <Tooltip
      isOpen={!!tooltipData} // Show only if tooltipData exists
      content={
        <div style={{ maxWidth: "200px", wordBreak: "break-word", paddingRight: "20px" }}>
          <div><strong>Part Number:</strong> {tooltipData?.parts_number}</div>
          <div><strong>Description:</strong> {tooltipData?.description}</div>
          <CtyButton
            className="absolute top-1 right-1 cursor-pointer text-white text-xs p-1 rounded-full min-w-0 bg-transparent"
            onPress={() => setTooltipData(null)} title='Close Tooltip'
          >
            ✕
          </CtyButton>
        </div>
      }
      placement="top"
      className="bg-[#003A79] text-white p-2 rounded-lg shadow-lg"
      isDismissable={true}
      showArrow
      color='primary'
    >
      <div
        style={{
          position: "absolute",
          left: `${tooltipPosition?.x}px`,
          top: `${tooltipPosition?.y}px`,
          transform: "translate(-50%, -100%)",
          zIndex: 9999,
        }}
      >
      </div>
    </Tooltip>
  );
};





export { PartSvgViewerInline, PartsSvgViewer };

